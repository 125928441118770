import React, { useRef } from 'react';
import { useI18n } from 'i18n';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ReactComponent as Man } from 'assets/icons/FinancingOffer/man.svg';
import { ReactComponent as Calculation } from 'assets/icons/FinancingOffer/calculation.svg';
import { ReactComponent as TotalCosts } from 'assets/icons/FinancingOffer/total-costs.svg';
import { ReactComponent as EuroSymbol } from 'assets/icons/FinancingOffer/euro-symbol.svg';
import { ReactComponent as GroupOfCircles } from 'assets/icons/Hero/group-of-circles.svg';
import { ReactComponent as Shadow } from 'assets/icons/Hero/shadow.svg';
import cn from 'classnames';
import { Link } from 'gatsby';
import useImageSize from 'hooks/useImageSize';
import { Visible } from 'components/Grid';
import { appHomeUrl } from 'config/settings';
import { trackCTAClick } from 'utils/analytics';
import routePaths from 'constants/route-paths';
import Button from '../common/Button';
import s from './FinancingOffer.module.scss';

const FinancingOffer = ({ isVisible, ratio }) => {
  const { t, locale } = useI18n();
  const containerRef = useRef();
  const { width, height } = useImageSize(containerRef, { ratio });
  return (
    <Grid className={isVisible ? s.animateIn : ''}>
      <Row className={s.row}>
        <Col md={6} className={s.firstCol}>
          <h2 className="title">{t('financing_offer.title')}</h2>
          <p className={cn('text', s.animatedText)}>{t('financing_offer.text')}</p>
          <div className={s.buttonsWrapper}>
            <Link className={s.moreDetails} to={routePaths[locale].financing_calculator}>
              <Button>{t('financing_offer.btn_label')}</Button>
            </Link>
            <Visible xs sm>
              <a className={s.ctaBtn} href={`${appHomeUrl}?lang=${locale}`} target="_blank" rel="noreferrer">
                <Button variant="secondary" onClick={() => trackCTAClick('Finanzierungsrechner')}>
                  {t('navigation.btn_label')}
                </Button>
              </a>
            </Visible>
          </div>
        </Col>
        <Col md={6} className={s.secondCol}>
          <div ref={containerRef} className={s.secondColBoxContainer}>
            <div style={{ height, width }} className={s.secondColBox}>
              <Man className={s.man} />
              <Calculation className={s.calculation} />
              <TotalCosts className={s.totalCosts} />
              <EuroSymbol className={s.euroSymbol} />
              <GroupOfCircles className={s.upGroupOfCircles} />
              <GroupOfCircles className={s.downGroupOfCircles} />
              <Shadow className={s.shadow} />
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

export default FinancingOffer;
