export const partners = [
  {
    id: 1,
    logo: 'enu-logo.png',
    title: 'our_partners.enu.title',
    description: 'our_partners.enu.description',
    altText: 'alt_text.partner_logos.enu',
  },
  {
    id: 2,
    logo: 'hyponoe-logo.png',
    title: 'our_partners.hyponoe.title',
    description: 'our_partners.hyponoe.description',
    altText: 'alt_text.partner_logos.hypo_noe',
  },
  {
    id: 3,
    logo: 'sprengnetter-logo.png',
    title: 'our_partners.sprengnetter.title',
    imageProps: {
      style: {
        maxHeight: '80px',
        maxWidth: '200px',
      },
    },
    description: 'our_partners.sprengnetter.description',
    altText: 'alt_text.partner_logos.sprengnetter',
  },
  {
    id: 4,
    logo: 'riz-up-logo.png',
    title: 'our_partners.riz_up.title',
    description: 'our_partners.riz_up.description',
    altText: 'alt_text.partner_logos.riz_up',
  },
];

export const media = [
  { id: 5, logo: 'orf-logo.png', altText: 'alt_text.media_logos.orf' },
  { id: 6, logo: 'brutkasten-logo.png', altText: 'alt_text.media_logos.brutkasten' },
];
