import React, { useRef } from 'react';
import { useI18n } from 'i18n';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ReactComponent as House } from 'assets/icons/EnvironmentCheck/house.svg';
import { ReactComponent as School } from 'assets/icons/EnvironmentCheck/school.svg';
import { ReactComponent as ShoppingCart } from 'assets/icons/EnvironmentCheck/shopping-cart.svg';
import { ReactComponent as DumbBell } from 'assets/icons/EnvironmentCheck/dumb-bell.svg';
import { ReactComponent as MedicineIcon } from 'assets/icons/EnvironmentCheck/medicine-icon.svg';
import { ReactComponent as Form } from 'assets/icons/EnvironmentCheck/form.svg';
import cn from 'classnames';
import { Visible } from 'components/Grid';
import useImageSize from 'hooks/useImageSize';
import { appHomeUrl } from 'config/settings';
import Button from 'components/common/Button';
import { trackCTAClick } from 'utils/analytics';
import s from './EnvironmentCheck.module.scss';

const EnvironmentCheck = ({ isVisible, ratio }) => {
  const { t, locale } = useI18n();
  const containerRef = useRef();
  const { width, height } = useImageSize(containerRef, { ratio });
  return (
    <Grid className={isVisible ? s.animateIn : ''}>
      <Row className={s.row}>
        <Col md={7} className={s.firstCol}>
          <h2 className="title">{t('environment_check.title')}</h2>
          <p className={cn('text', s.animatedText)}>{t('environment_check.text')}</p>
          <Visible xs sm>
            <div className={s.buttonsWrapper}>
              <a className={s.ctaBtn} href={`${appHomeUrl}?lang=${locale}`} target="_blank" rel="noreferrer">
                <Button variant="secondary" onClick={() => trackCTAClick('Umgebungscheck')}>
                  {t('navigation.btn_label')}
                </Button>
              </a>
            </div>
          </Visible>
        </Col>
        <Col md={5} className={s.secondCol}>
          <div ref={containerRef} className={s.secondColBoxContainer}>
            <div style={{ height, width }} className={s.secondColBox}>
              <House className={s.house} />
              <School className={s.school} />
              <ShoppingCart className={s.shoppingCart} />
              <DumbBell className={s.dumbBell} />
              <MedicineIcon className={s.medicineIcon} />
              <Form className={s.form} />
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

export default EnvironmentCheck;
