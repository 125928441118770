import React, { useCallback } from 'react';
import { useI18n } from 'i18n';
import { Grid, Row } from 'react-flexbox-grid';
import Button from 'components/common/Button';
import energyBalanceImage from 'assets/images/energy-balance.png';
import savingsPotentialImage from 'assets/images/savings-potential.png';
import costSavingsImage from 'assets/images/hero/cost-savings.png';
import RenovationButton from 'components/common/RenovationButton';
import { FAQ_ELEMENT_ID } from 'components/Home/FAQ/FAQ';

import s from './Hero.module.scss';

const EXTRA_SCROLL_PADDING = 80;

const Hero = () => {
  const { t } = useI18n();
  const scrollToFAQ = useCallback(() => {
    const element = document.getElementById(FAQ_ELEMENT_ID);
    const y = element.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - EXTRA_SCROLL_PADDING,
      behavior: 'smooth',
    });
  }, []);
  return (
    <Grid>
      <h1 dangerouslySetInnerHTML={{ __html: t('hero.title') }} className={s.title} />
      <p className={s.description}>{t('hero.description')}</p>
      <Row className={s.buttonsContainer}>
        <RenovationButton title="hero.start_now" />
        <Button onClick={scrollToFAQ} variant="outlined">
          {t('hero.learn_more')}
        </Button>
      </Row>
      <div className={s.imagesContainer}>
        <img className={s.img} alt="energyBalanceImage" src={energyBalanceImage} />
        <img className={s.img} alt="savingsPotentialImage" src={savingsPotentialImage} />
        <img className={s.img} alt="costSavingsImage" src={costSavingsImage} />
      </div>
    </Grid>
  );
};

export default Hero;
