import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { AppContextProvider } from 'contexts/AppContext';
import Hero from 'components/Hero';
import BudgetPlanner from 'components/BudgetPlanner';
import PropertyEstimation from 'components/PropertyEstimation';
import EnvironmentCheck from 'components/EnvironmentCheck';
import FinancingOffer from 'components/FinancingOffer';
import { isMobile } from 'react-device-detect';
import PageLayout from 'components/layout/PageLayout';
import QuickStart from 'components/QuickStart/QuickStart';
import CustomerVoices from 'components/CustomerVoices/CustomerVoices';
import LastSection from 'components/LastSection';
import { I18nProvider } from 'i18n';
import EnergyAssessment from 'components/Home/EnergyAssessment';
import SectionList from 'components/Home/SectionList';
import Instruction from 'components/Home/Instruction';
import FAQ from 'components/Home/FAQ';

import 'styles/index.scss';

const visibilitySensorConfig = {
  partialVisibility: true,
  intervalDelay: 300,
  offset: { bottom: 250 },
};

const components = [
  Hero,
  EnergyAssessment,
  SectionList,
  QuickStart,
  Instruction,
  FAQ,
  CustomerVoices,
  BudgetPlanner,
  PropertyEstimation,
  EnvironmentCheck,
  FinancingOffer,
  LastSection,
];

export default function Home() {
  const aspectRatio = isMobile ? 0.93 : 1.16;
  return (
    <AppContextProvider>
      <I18nProvider>
        <PageLayout
          bgColor="white"
          fluid
          main
          i18nMetaTitle="home.meta_title"
          i18nMetaDescription="home.meta_description"
        >
          {components.map((C) => (
            <VisibilitySensor {...visibilitySensorConfig} key={C}>
              {({ isVisible }) => <C {...{ isVisible, ratio: aspectRatio }} />}
            </VisibilitySensor>
          ))}
        </PageLayout>
      </I18nProvider>
    </AppContextProvider>
  );
}
