import React from 'react';
import cn from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
import { useI18n } from 'i18n';

import s from './Stepper.module.scss';

const Stepper = ({ steps }) => {
  const { t } = useI18n();
  return (
    <div className={s.stepper}>
      {steps.map(({ title, description, isActive }, index) => (
        <Row key={title} className={s.step}>
          <Col className={s.circleWrapper}>
            {index !== steps.length - 1 ? <div className={s.line} /> : null}
            <div className={cn(s.circle, isActive && s.activeCircle)}>{index + 1}</div>
          </Col>
          <Col sm={9} md={7} xs>
            <h3 className={s.title}>{t(title)}</h3>
            <p className={s.description}>{t(description)}</p>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default Stepper;
