import { useI18n } from 'i18n';
import React, { useRef } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import cn from 'classnames';

import s from './CollapsibleItem.module.scss';

const CollapsibleItem = ({ title, children, isLastItem, defaultIsExpanded = false }) => {
  const [height, setHeight] = React.useState();
  const [isExpanded, setIsExpanded] = React.useState(defaultIsExpanded);

  const contentRef = useRef();

  const handleToggle = React.useCallback(
    (e) => {
      e.preventDefault();
      setIsExpanded(!isExpanded);
      setHeight(contentRef.current.clientHeight);
    },
    [isExpanded],
  );

  React.useLayoutEffect(() => {
    if (defaultIsExpanded) setTimeout(() => setHeight(contentRef.current.clientHeight), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useI18n();

  const currentHeight = isExpanded ? height : 0;
  return (
    <div
      role="button"
      className={cn(s.container, isExpanded && s.expanded, isLastItem && s.borderBottom)}
      tabIndex={0}
      onKeyDown={handleToggle}
      onClick={handleToggle}
    >
      <div className={s.titleContainer}>
        <h3>{t(title)}</h3>
        <PlusIcon className={s.plusIcon} />
      </div>
      <div className={s.cardCollapse} style={{ height: `${currentHeight}px` }}>
        <div className={s.content} ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CollapsibleItem;
