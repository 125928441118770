import React from 'react';
import Stepper from 'components/common/Stepper';
import { Grid } from 'react-flexbox-grid';
import { useI18n } from 'i18n';
import RenovationButton from 'components/common/RenovationButton';

const steps = [
  {
    title: 'instruction.individual_renovation_concept',
    description: 'instruction.individual_renovation_concept_description',
    isActive: true,
  },
  { title: 'instruction.clarify', description: 'instruction.clarify_description' },
  { title: 'instruction.select_craftsmen', description: 'instruction.select_craftsmen_description' },
];

const Instruction = () => {
  const { t } = useI18n();
  return (
    <Grid className="mt-100 pl-40">
      <h1 className="pb-30">{t('instruction.title')}</h1>
      <Stepper steps={steps} />
      <RenovationButton containerClassName="display-inline" className="ml-40" title="instruction.btn_label" />
    </Grid>
  );
};

export default Instruction;
