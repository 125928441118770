import React from 'react';
import savingsPotentialImage from 'assets/images/savings-potential.png';
import costSavingsImage from 'assets/images/hero/cost-savings.png';
import reportImage from 'assets/images/report.png';
import valueOfProperty from 'assets/images/value-of-property.png';
import StickyContainer from 'components/common/StickyContainer';

import Section from './Section';

import s from './Section.module.scss';

const sectionListData = [
  {
    title: 'section_list.savings_potential',
    description: 'section_list.savings_potential_description',
    src: savingsPotentialImage,
  },
  {
    title: 'section_list.individual_report',
    description: 'section_list.individual_report_description',
    btnLabel: 'section_list.individual_report_btn_label',
    src: reportImage,
    reverse: true,
  },
  {
    title: 'section_list.funding_financing',
    description: 'section_list.funding_financing_description',
    src: costSavingsImage,
  },
  {
    title: 'section_list.increase_property',
    description: 'section_list.increase_property_description',
    btnLabel: 'section_list.increase_property_btn_label',
    src: valueOfProperty,
    reverse: true,
  },
];

const SectionList = () => {
  return (
    <div className={s.list}>
      {sectionListData.map((section) => (
        <StickyContainer key={section.title}>
          <Section {...section} />
        </StickyContainer>
      ))}
    </div>
  );
};

export default SectionList;
