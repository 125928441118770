/* eslint-disable no-nested-ternary */
import { useI18n } from 'i18n';
import React from 'react';
import Slider from 'react-slick';
import { ReactComponent as QuotesIcon } from 'assets/icons/quotes.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isMobile } from 'react-device-detect';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { get } from 'lodash';
import s from './CustomerVoices.module.scss';

const settings = {
  arrows: false,
  autoplaySpeed: 5000,
  autoplay: true,
  dots: true,
  infinite: true,
  accessibility: true,
  speed: 500,
  slidesToShow: isMobile ? 1 : 2,
  slidesToScroll: isMobile ? 1 : 2,
};

const CustomerVoices = () => {
  const { t, i18n } = useI18n();
  const translations = i18n.getTranslations();
  const voices = get(translations, 'customer_voices.voices', []);

  return (
    <Grid>
      <Row>
        <Col md={12}>
          <section className={s.customerVoicesSection}>
            <h2 className={s.title}>{t('customer_voices.title')}</h2>
            <Slider className={s.voices} {...settings}>
              {voices.map(({ text, address }) => (
                <div className={s.card} key={text}>
                  <QuotesIcon className={s.quotes} />
                  <p className={s.text}>{text}</p>
                  <p className={s.address}>{address}</p>
                </div>
              ))}
            </Slider>
          </section>
        </Col>
      </Row>
    </Grid>
  );
};

export default CustomerVoices;
