import CollapsibleItem from 'components/common/CollapsibleItem';
import { useI18n } from 'i18n';
import React from 'react';
import { Col, Grid } from 'react-flexbox-grid';

import s from './FAQ.module.scss';

export const FAQ_ELEMENT_ID = 'FAQ';

const FAQ = () => {
  const { t } = useI18n();
  return (
    <Grid id={FAQ_ELEMENT_ID} className={s.container}>
      <Col sm={12} md={9} xs>
        <h1 className={s.title}>{t('FAQ.title')}</h1>
        <CollapsibleItem defaultIsExpanded title="FAQ.question_1">
          <p className={s.answer}>{t('FAQ.answer_1')}</p>
        </CollapsibleItem>
        <CollapsibleItem title="FAQ.question_2">
          <p className={s.answer}>{t('FAQ.answer_2')}</p>
        </CollapsibleItem>
        <CollapsibleItem title="FAQ.question_3">
          <p className={s.answer}>{t('FAQ.answer_3')}</p>
        </CollapsibleItem>
        <CollapsibleItem isLastItem title="FAQ.question_4">
          <p className={s.answer}>{t('FAQ.answer_4')}</p>
        </CollapsibleItem>
      </Col>
    </Grid>
  );
};

export default FAQ;
