import React, { useRef } from 'react';
import { useI18n } from 'i18n';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ReactComponent as Woman } from 'assets/icons/PropertyEstimation/woman.svg';
import { ReactComponent as SearchMagnifier } from 'assets/icons/PropertyEstimation/search-magnifier.svg';
import { ReactComponent as FiveMin } from 'assets/icons/Hero/five-min.svg';
import { ReactComponent as Shadow } from 'assets/icons/Hero/shadow.svg';
import { ReactComponent as House } from 'assets/icons/Hero/house.svg';
import { ReactComponent as Form } from 'assets/icons/Hero/form.svg';
import { ReactComponent as ActiveInput } from 'assets/icons/Hero/active-input.svg';
import { ReactComponent as GroupOfCircles } from 'assets/icons/Hero/group-of-circles.svg';
import cn from 'classnames';
import useImageSize from 'hooks/useImageSize';
import { appHomeUrl } from 'config/settings';
import { Visible } from 'components/Grid';
import Button from 'components/common/Button';
import { trackCTAClick } from 'utils/analytics';
import s from './PropertyEstimation.module.scss';

const PropertyEstimation = ({ isVisible, ratio }) => {
  const { t, locale } = useI18n();
  const containerRef = useRef();
  const { width, height } = useImageSize(containerRef, { ratio });

  return (
    <Grid className={isVisible ? s.animateIn : ''}>
      <Row className={s.row}>
        <Col md={6} className={s.firstCol}>
          <h2 className="title">{t('property_estimation.title')}</h2>
          <p className={cn('text', s.animatedText)}>{t('property_estimation.text')}</p>
          <Visible xs sm>
            <div className={s.buttonsWrapper}>
              <a className={s.ctaBtn} href={`${appHomeUrl}?lang=${locale}`} target="_blank" rel="noreferrer">
                <Button variant="secondary" onClick={() => trackCTAClick('Immobilienbewertung')}>
                  {t('navigation.btn_label')}
                </Button>
              </a>
            </div>
          </Visible>
        </Col>
        <Col md={6} className={s.secondCol}>
          <div ref={containerRef} className={s.secondColBoxContainer}>
            <div style={{ height, width }} className={s.secondColBox}>
              <Woman className={s.woman} />
              <SearchMagnifier className={s.searchMagnifier} />
              <FiveMin className={s.fiveMin} />
              <Shadow className={s.shadow} />
              <House className={s.house} />
              <Form className={s.form} />
              <ActiveInput className={s.activeInput} />
              <GroupOfCircles className={s.upGroupOfCircles} />
              <GroupOfCircles className={s.downGroupOfCircles} />
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

export default PropertyEstimation;
