/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import { useI18n } from 'i18n';
import Slider from 'react-slick';
import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { media, partners } from './media-partners';

import s from './LastSection.module.scss';

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  accessibility: true,
  speed: 500,
  slidesToShow: isMobile ? 1 : isTablet ? 3 : 4,
  slidesToScroll: 1,
};

const Media = ({ t }) => (
  <div className={s.subSection} style={{ maxWidth: '700px' }}>
    <h3 className={s.title}>{t('media.title')}</h3>
    <p className={s.text}>{t('media.text')}</p>
    <Slider {...{ ...settings, slidesToShow: isMobile ? 1 : 2 }} className={s.logos}>
      {media.map(({ logo, id, altText }) => (
        <img className={s.mediaLogo} key={id} src={require(`assets/images/media/${logo}`)} alt={t(altText)} />
      ))}
    </Slider>
  </div>
);

const Partners = ({ t }) => (
  <div className={s.subSection}>
    <h3 className={s.title}>{t('our_partners.title')}</h3>
    <p className={s.text}>{t('our_partners.text')}</p>
    <Slider {...settings} className={s.logos}>
      {partners.map(({ id, logo, altText, title, description, imageProps }) => (
        <div className={s.partner} key={id}>
          <div className={s.logoBox}>
            <img className={s.logo} {...imageProps} src={require(`assets/images/partners/${logo}`)} alt={t(altText)} />
          </div>
          <div className={s.text}>
            <h3 className={s.title}>{t(title)}</h3>
            <p className={s.description}>{t(description)}</p>
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

const LastSection = () => {
  const { t } = useI18n();
  return (
    <Grid style={{ marginBottom: '60px' }}>
      <Row>
        <Col xs={12}>
          <Partners {...{ t }} />
          <Media {...{ t }} />
        </Col>
      </Row>
    </Grid>
  );
};

export default LastSection;
