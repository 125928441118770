import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useI18n } from 'i18n';
import { ReactComponent as HomeImage } from 'assets/images/house.svg';

import s from './EnergyAssessment.module.scss';

const EnergyAssessment = () => {
  const { t } = useI18n();
  return (
    <Row className={s.container}>
      <Grid>
        <h1 className={s.title}>{t('energy_assessment.title')}</h1>
        <Row className={s.descriptionContainer}>
          <Col md={6} mdOffset={1}>
            <p className={s.description}>{t('energy_assessment.description')}</p>
          </Col>
          <Col md={1} />
          <Col md={4} className={s.houseImageContainer}>
            <HomeImage />
          </Col>
        </Row>
      </Grid>
    </Row>
  );
};

export default EnergyAssessment;
