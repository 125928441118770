/* eslint-disable react/no-danger */
import { useI18n } from 'i18n';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import renovationHouseSrc from 'assets/images/quick-start/renovation-house.svg';
import buyingHouseSrc from 'assets/images/quick-start/buying-house.svg';
import newBuildingSrc from 'assets/images/quick-start/new-building.svg';
import newApartmentSrc from 'assets/images/quick-start/new-apartment.svg';
import propertyValuationSrc from 'assets/images/quick-start/property-valuation.svg';
import lifestyleCalculatorSrc from 'assets/images/quick-start/lifestyle-calculator.svg';
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward.svg';
import Button from 'components/common/Button';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { appHomeUrl } from 'config/settings';
import debounce from 'lodash/debounce';
import Alert from 'components/common/Alert/Alert';
import RenovationButton from 'components/common/RenovationButton';

import s from './QuickStart.module.scss';

const calculatorsMenu = [
  {
    imgSrc: lifestyleCalculatorSrc,
    type: 'lifestyle_calculator',
    altText: 'alt_text.project_types.lifestyle_calculator',
  },
  {
    imgSrc: renovationHouseSrc,
    type: 'renovation_house',
    altText: 'alt_text.project_types.renovation_house',
  },
  {
    imgSrc: buyingHouseSrc,
    type: 'buying_house',
    altText: 'alt_text.project_types.buying_house',
  },
  {
    imgSrc: newBuildingSrc,
    type: 'new_building',
    altText: 'alt_text.project_types.new_building',
  },
  {
    imgSrc: newApartmentSrc,
    type: 'new_apartment',
    altText: 'alt_text.project_types.new_apartment',
  },
  {
    imgSrc: propertyValuationSrc,
    type: 'property_valuation',
    altText: 'alt_text.project_types.property_valuation',
  },
];

const DesktopQuickStart = ({ t, locale }) => {
  return (
    <Grid className={s.animateIn}>
      <Row className={s.row} between="xs">
        <Col md={12}>
          <div className={s.quickStartDesktop}>
            <h2 className={s.title}>{t('quick_start.title')}</h2>
            {/* <p className={s.text}>{t('quick_start.text')}</p> */}
            <Grid>
              <Row className={s.ctaMenu}>
                {calculatorsMenu.map(({ imgSrc, altText, type }) => {
                  return (
                    <Col key={type} md={4} xl={2} className={s.col}>
                      <img src={imgSrc} className={s.projectTypeImg} alt={t(altText)} />
                      <div className={s.projectType}>{t(`project_types.${type}`)}</div>
                      <a href={`${appHomeUrl}?type=${type}&lang=${locale}`} target="_blank" rel="noreferrer">
                        <Button variant="secondary">{t('buttons.start')}</Button>
                      </a>
                    </Col>
                  );
                })}
              </Row>
            </Grid>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

const MobileQuickStart = ({ t, locale }) => {
  const [calculatorsNumber, setCalculatorsNumber] = useState(3);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();

  const handleToggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
    const number = !menuOpen ? calculatorsMenu.length : 1;
    setCalculatorsNumber(number);
  }, [menuOpen]);

  const handleScroll = useCallback(
    debounce(() => {
      menuRef.current?.classList?.toggle(s.hideMenu, window.scrollY >= 3000 && menuRef.current);
    }, 300),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, [handleScroll]);

  return (
    <div className={s.quickStartMobile} ref={menuRef}>
      <ul
        className={s.calculators}
        style={{ transform: `translateY(${(calculatorsMenu.length - calculatorsNumber) * 60}px)` }}
      >
        {calculatorsMenu.map(({ imgSrc, type, altText }) => {
          return (
            <li key={type} className={s.calculator}>
              <div className={s.imgWrapper}>
                <img src={imgSrc} className={s.img} alt={altText} />
              </div>
              <div className={s.projectType}>{t(`project_types.${type}`)}</div>
              <a href={`${appHomeUrl}?type=${type}&lang=${locale}`} target="_blank" rel="noreferrer">
                <button type="button" className={s.startButton}>
                  <span>{t('buttons.start')}</span>
                  <span>
                    <ArrowForward />
                  </span>
                </button>
              </a>
            </li>
          );
        })}
      </ul>
      <Button variant="secondary" className={s.toggleButton} onClick={handleToggleMenu}>
        <span
          dangerouslySetInnerHTML={{
            __html: menuOpen
              ? t('buttons.close')
              : t('quick_start.view_all_calculators', { num: calculatorsMenu.length }),
          }}
        />
      </Button>
    </div>
  );
};

const QuickStart = () => {
  const { t, locale } = useI18n();
  return (
    <>
      <Alert>
        <h1>{t('quick_start.alert_title')}</h1>
        <p>{t('quick_start.alert_description')}</p>
        <RenovationButton
          containerClassName={s.renovationButton}
          hideArrow
          variant="outlined"
          title="quick_start.alert_btn_label"
        />
      </Alert>
      <section className={s.quickStartSection}>
        <MobileQuickStart {...{ t, locale }} />
        <DesktopQuickStart {...{ t, locale }} />
      </section>
    </>
  );
};

export default QuickStart;
