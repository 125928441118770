import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useI18n } from 'i18n';
import RenovationButton from 'components/common/RenovationButton';

import s from './Section.module.scss';

const Section = ({ title, description, src = '', btnLabel, reverse }) => {
  const { t } = useI18n();
  return (
    <Grid className={s.section}>
      <Row className={reverse && s.reverse}>
        <Col md={6} className={s.centered}>
          <h1 className={s.title}>{t(title)}</h1>
          <p className={s.description}>{t(description)}</p>
          {btnLabel ? <RenovationButton title={btnLabel} /> : null}
        </Col>
        <Col md={6} className={s.centered}>
          <img src={src} className={s.sectionImage} width="100%" alt="" />
        </Col>
      </Row>
    </Grid>
  );
};

export default Section;
