import React, { useRef } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ReactComponent as UpArrow } from 'assets/icons/BudgetPlanner/up-arrow-in-circle.svg';
import { ReactComponent as DownArrow } from 'assets/icons/BudgetPlanner/down-arrow-in-circle.svg';
import { ReactComponent as Chart } from 'assets/icons/BudgetPlanner/chart.svg';
import { ReactComponent as Form } from 'assets/icons/BudgetPlanner/form.svg';
import { ReactComponent as Calculator } from 'assets/icons/BudgetPlanner/calculator.svg';
import { ReactComponent as GroupOfCircles } from 'assets/icons/Hero/group-of-circles.svg';
import cn from 'classnames';
import { Link } from 'gatsby';
import useImageSize from 'hooks/useImageSize';
import { Visible } from 'components/Grid';
import { appHomeUrl } from 'config/settings';
import { trackCTAClick } from 'utils/analytics';
import { useI18n } from 'i18n';
import routePaths from 'constants/route-paths';
import Button from '../common/Button';
import s from './BudgetPlanner.module.scss';

const BudgetPlanner = ({ isVisible, ratio = 1.16 }) => {
  const { t, locale } = useI18n();
  const containerRef = useRef();
  const { width, height } = useImageSize(containerRef, { ratio });
  return (
    <Grid className={isVisible ? s.animateIn : ''}>
      <Row className={s.row}>
        <Col md={6} className={s.firstCol}>
          <h2 className="title">{t('budget_planner.title')}</h2>
          <p className={cn('text', s.animatedText)}>{t('budget_planner.text')}</p>
          <div className={s.buttonsWrapper}>
            <Link className={s.moreDetails} to={routePaths[locale].home_loan_calculator}>
              <Button>{t('budget_planner.btn_label')}</Button>
            </Link>
            <Visible xs sm>
              <a className={s.ctaBtn} href={`${appHomeUrl}?lang=${locale}`} target="_blank" rel="noreferrer">
                <Button variant="secondary" onClick={() => trackCTAClick('Wohnkredit-rechner')}>
                  {t('navigation.btn_label')}
                </Button>
              </a>
            </Visible>
          </div>
        </Col>
        <Col md={6} className={s.secondCol}>
          <div ref={containerRef} className={s.secondColBoxContainer}>
            <div style={{ height, width }} className={s.secondColBox}>
              <UpArrow className={s.upArrow} />
              <DownArrow className={s.downArrow} />
              <Chart className={s.chart} />
              <Form className={s.form} />
              <Calculator className={s.calculator} />
              <GroupOfCircles className={s.upGroupOfCircles} />
              <GroupOfCircles className={s.downGroupOfCircles} />
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

export default BudgetPlanner;
