import React from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useImageSize = (containerRef, { ratio }) => {
  const [width, setWidth] = React.useState(0);
  const [maxHeight, setMaxHeight] = React.useState(0);

  React.useLayoutEffect(() => {
    setWidth(containerRef.current.offsetWidth);
    setMaxHeight(containerRef.current.offsetWidth * ratio);
  }, [containerRef, ratio]);

  useResizeObserver(containerRef, (entry) => {
    setWidth(entry.contentRect.width);
    setMaxHeight(entry.contentRect.height);
  });

  const height = ratio * width;
  const size = { width, height };

  if (height > maxHeight) {
    size.height = maxHeight;
    size.width = maxHeight / ratio;
  }
  return size;
};

export default useImageSize;
